import {
  createContext, useCallback, useContext, useEffect, useState,
} from 'react';
import { useDatabase } from './DatabaseProvider';
import logger from '../utils/logger';
import { calculateKpiMetaData } from './utils';

const log = logger('ERProvider');

const EnergieReporterContext = createContext(null);

export function ERProvider({ children }) {
  const { getAllDocsByKey } = useDatabase();

  // App Interaction Logic (search, select municipality or canton)
  const [isLoading, setIsLoading] = useState(true);
  const [selections, setSelections] = useState([]);
  const [autoComplete, setAutoComplete] = useState([]);
  // Infos (object type, geoJson etc.)
  const [municipalityInfo, setMunicipalityInfo] = useState({});
  const [countryInfo, setCountryInfo] = useState({});
  const [cantonInfo, setCantonInfo] = useState({});
  // KPIs
  const [municipalityKpi, setMunicipalityKpi] = useState({});
  const [countryKpiMap, setCountryKpiMap] = useState({});
  const [municipalityTypeKpi, setMunicipalityTypeKpi] = useState({});
  const [cantonKpi, setCantonKpi] = useState({});
  // KPIs Metadata (here we store meta information about the KPIs,
  // e.g. the minimum and maximum values of the KPI, the average value etc.)
  // To access the metadata of a KPI, use the KPI name as the key, e.g.:
  // 'elecconsumption',
  // 'emobilityshare',
  // 'pvusage',
  // 'renelecproduction',
  // 'renewableheatingshare',
  const [cantonKpiMetaData, setCantonKpiMetaData] = useState({});
  const [municipalityKpiMetaData, setMunicipalityKpiMetaData] = useState({});

  useEffect(() => {
    if (!Object.keys(cantonKpi).length) return;

    const newMetaData = calculateKpiMetaData(cantonKpi);

    setCantonKpiMetaData(newMetaData);
  }, [cantonKpi]);

  useEffect(() => {
    if (!Object.keys(municipalityKpi).length) return;

    const newMetaData = calculateKpiMetaData(municipalityKpi);

    setMunicipalityKpiMetaData(newMetaData);
  }, [municipalityKpi]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const [
          mInfos,
          mKpi,
          caInfo,
          caKpi,
          coInfo,
          coKpi,
          mTypeKpi,
        ] = await Promise.all([
          getAllDocsByKey('municipalityinfo'),
          getAllDocsByKey('municipalitykpi'),
          getAllDocsByKey('cantoninfo'),
          getAllDocsByKey('cantonkpi'),
          getAllDocsByKey('countryinfo'),
          getAllDocsByKey('countrykpi'),
          getAllDocsByKey('municipality_typekpi'),
        ]);

        log.debug('Database Response: ', {
          mInfos,
          mKpi,
          caInfo,
          caKpi,
          coInfo,
          coKpi,
          mTypeKpi,
        });

        const autoCompleteData = []; // Prepare an empty array for autoComplete data
        const municipalityInfoObject = mInfos.rows.reduce((acc, row) => {
          // Extract the number after the colon
          const idNumber = row.id.split(':')[1];
          // Use this number as the key, and the document content as the value
          acc[idNumber] = row.doc;

          // Also prepare data for autoComplete state
          autoCompleteData.push({
            label: row.doc.regionName, // The name displayed in the autocomplete options
            id: idNumber, // The ID to identify the selected region
            type: 'municipality',
            municipalityType: row.doc.municipalityType,
          });

          return acc;
        }, {});
        const municipalityKpisObject = mKpi.rows.reduce((acc, row) => {
          // Split the ID to get the municipality number and the KPI type
          // eslint-disable-next-line no-unused-vars
          const [prefix, municipalityId, kpiType] = row.id.split(':');

          // Initialize the municipality object if not already done
          if (!acc[municipalityId]) {
            acc[municipalityId] = {};
          }

          // Assign the KPI data to the corresponding municipality and KPI type
          acc[municipalityId][kpiType] = row.doc;

          return acc;
        }, {});
        const cantonInfoObject = caInfo.rows.reduce((acc, row) => {
          // Extract the number after the colon
          const abbreviation = row.id.split(':')[1];
          // Use this number as the key, and the document content as the value
          acc[abbreviation] = row.doc;

          // Also prepare data for autoComplete state
          autoCompleteData.push({
            label: row.doc.regionName, // The name displayed in the autocomplete options
            id: abbreviation, // The ID to identify the selected region
            type: 'canton',
          });

          return acc;
        }, {});
        const cantonKpisObject = caKpi.rows.reduce((acc, row) => {
          // Split the ID to get the municipality number and the KPI type
          // eslint-disable-next-line no-unused-vars
          const [prefix, cantonId, kpiType] = row.id.split(':');

          // Initialize the municipality object if not already done
          if (!acc[cantonId]) {
            acc[cantonId] = {};
          }

          // Assign the KPI data to the corresponding municipality and KPI type
          acc[cantonId][kpiType] = row.doc;

          return acc;
        }, {});
        const countryKpisObject = coKpi.rows.reduce((acc, row) => {
          // Split the ID to get the municipality number and the KPI type
          // eslint-disable-next-line no-unused-vars
          const [prefix, abbreviation, kpiType] = row.id.split(':');

          // Initialize the municipality object if not already done
          if (!acc[kpiType]) {
            acc[kpiType] = {};
          }

          // Assign the KPI data to the corresponding municipality and KPI type
          acc[kpiType] = row.doc;

          return acc;
        }, {});
        const municipalityTypeKpiObject = mTypeKpi.rows.reduce((acc, row) => {
          // eslint-disable-next-line no-unused-vars
          const [prefix, municipalityType, kpiName] = row.id.split(':');
          // Initialize the municipality object if not already done
          if (!acc[municipalityType]) {
            acc[municipalityType] = {};
          }
          acc[municipalityType][kpiName] = row.doc;
          return acc;
        }, {});

        const countryInfoObject = coInfo.rows[0].doc;

        const sortedAutoComplete = autoCompleteData.sort((a, b) => -b.label.localeCompare(a.label));
        setAutoComplete(sortedAutoComplete);
        setMunicipalityInfo(municipalityInfoObject);
        setMunicipalityKpi(municipalityKpisObject);
        setCantonInfo(cantonInfoObject);
        setCantonKpi(cantonKpisObject);
        setCountryInfo(countryInfoObject);
        setCountryKpiMap(countryKpisObject);
        setMunicipalityTypeKpi(municipalityTypeKpiObject);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        log.error('Error loading data:', error);
      }
    })();
  }, [getAllDocsByKey]);

  const getHighestKpiValue = useCallback(() => {
    let highestValueCanton = 0;
    let highestValueMunicipality = 0;

    const isCantonSelected = selections.some((sel) => sel.type === 'canton');
    const isMunicipalitySelected = selections.some((sel) => sel.type === 'municipality');

    if (isCantonSelected) {
      Object.values(cantonKpiMetaData).forEach((kpi) => {
        if (kpi.max && kpi.max.value > highestValueCanton) {
          highestValueCanton = kpi.max.value;
        }
      });
    }

    if (isMunicipalitySelected) {
      Object.values(municipalityKpiMetaData).forEach((kpi) => {
        if (kpi.max && kpi.max.value > highestValueMunicipality) {
          highestValueMunicipality = kpi.max.value;
        }
      });
    }

    // Return the higher value between canton and municipality
    return Math.max(highestValueCanton, highestValueMunicipality);
  }, [cantonKpiMetaData, municipalityKpiMetaData, selections]);

  const getLowestKpiValue = useCallback(() => {
    let lowestValueCanton = Number.MAX_VALUE;
    let lowestValueMunicipality = Number.MAX_VALUE;

    const isCantonSelected = selections.some((sel) => sel.type === 'canton');
    const isMunicipalitySelected = selections.some((sel) => sel.type === 'municipality');

    if (isCantonSelected) {
      Object.values(cantonKpiMetaData).forEach((kpi) => {
        if (kpi.min && kpi.min.value < lowestValueCanton) {
          lowestValueCanton = kpi.min.value;
        }
      });
    }

    if (isMunicipalitySelected) {
      Object.values(municipalityKpiMetaData).forEach((kpi) => {
        if (kpi.min && kpi.min.value < lowestValueMunicipality) {
          lowestValueMunicipality = kpi.min.value;
        }
      });
    }

    // Return the lower value between canton and municipality
    return Math.min(lowestValueCanton, lowestValueMunicipality);
  }, [cantonKpiMetaData, municipalityKpiMetaData, selections]);

  const api = {
    selections,
    setSelections,
    autoComplete,
    isLoading,
    municipalityKpi,
    municipalityInfo,
    countryInfo,
    cantonInfo,
    countryKpiMap,
    cantonKpi,
    cantonKpiMetaData,
    municipalityKpiMetaData,
    municipalityTypeKpi,
    highestKpiValue: getHighestKpiValue(),
    lowestKpiValue: getLowestKpiValue(),
  };

  return (
    <EnergieReporterContext.Provider value={api}>
      {children}
    </EnergieReporterContext.Provider>
  );
}

/**
 * Custom React hook to access the EnergieReporter context.
 *
 * @returns {Object} The API object containing various state values and functions.
 *
 * @throws {Error} Throws an error if used outside the EnergieReporterContext.Provider.
 *
 * @property {Array} selections - Array of selected regions or cantons, each with type and id.
 * @property {Function} setSelections - Function to update the selections array.
 * @property {Array} autoComplete - Array of data for the autocomplete functionality,
 *                                  with label, id, and type of each entry.
 * @property {boolean} isLoading - Boolean indicating if the application is currently loading data.
 * @property {Object} municipalityKpi - Object containing key performance
 *                                      indicators for municipalities.
 * @property {Object} municipalityInfo - Object containing information about municipalities.
 * @property {Object} countryInfo - Object containing information about the country.
 * @property {Object} cantonInfo - Object containing information about the cantons.
 * @property {Object} countryKpiMap - Object mapping key performance indicators to the country.
 * @property {Object} cantonKpi - Object containing key performance indicators for cantons.
 * @property {Object} cantonKpiMetaData - Metadata about canton KPIs,
 *                                        including min, max, and average values.
 * @property {Object} municipalityKpiMetaData - Metadata about municipality KPIs,
 *                                              including min, max, and average values.
 * @property {Object} municipalityTypeKpi - Object containing key performance indicators
 *                                          for different types of municipalities.
 * @property {number} highestKpiValue - The highest key performance indicator value,
 *                                      either from canton or municipality data.
 * @property {number} lowestKpiValue - The lowest key performance indicator value,
 *                                     either from canton or municipality data.
 */
export function useER() {
  const context = useContext(EnergieReporterContext);
  if (context === null) {
    throw new Error('useER must be used inside a EnergieReporterContext.Provider');
  }
  return context;
}
