import { marked } from 'marked';
import ReactDOMServer from 'react-dom/server';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import React from 'react';

function decodeHtmlEntities(text) {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}

const renderer = {
  table(header, body) {
    return ReactDOMServer.renderToString(
      <TableContainer component={Paper} sx={{ marginTop: '12px', maxWidth: 800 }}>
        <Table size="small">
          <TableHead dangerouslySetInnerHTML={{ __html: header }} />
          <TableBody dangerouslySetInnerHTML={{ __html: body }} />
        </Table>
      </TableContainer>,
    );
  },
  // Note: even if it is marked as unused in IDE it is still used by marked
  tablerow(content) {
    return ReactDOMServer.renderToString(
      <TableRow dangerouslySetInnerHTML={{
        __html: content,
      }}
      />,
    );
  },
  // Note: even if it is marked as unused in IDE it is still used by marked
  tablecell(content, flags) {
    const Tag = flags.header
      ? (
        <TableCell
          size="small"
          component="th"
          scope="row"
          dangerouslySetInnerHTML={{
            __html: `<b>${content}</b>`,
          }}
        />
      )
      : (
        <TableCell size="small">
          <Typography
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </TableCell>
      );
    return ReactDOMServer.renderToString(Tag);
  },
  link(href, title, text) {
    const titleAttr = title ? `title="${title}"` : '';
    return `<a href="${href}" target="_blank" rel="noopener noreferrer" ${titleAttr}>${text}</a>`;
  },
  // Note: even if it is marked as unused in IDE it is still used by marked
  heading(text, level, raw) {
    const decodedText = decodeHtmlEntities(text);
    const cleanedRaw = raw.replace(/\{.*?\}/g, '');
    const anchor = cleanedRaw.toLowerCase().trim().replace(/\s+/g, '-');

    const headingLevel = `h${level}`;
    const Tag = (
      <Typography
        variant="b"
        component={headingLevel}
        id={`heading-${anchor}`}
        sx={{
          margin: 0,
          ...(level === 2 && {
            marginTop: '28px',
          }),
          lineHeight: '1.3',
        }}
      >
        {decodedText}
      </Typography>
    );

    return ReactDOMServer.renderToString(Tag);
  },
};

function useMarkdown() {
  return (markdownText) => {
    marked.use({ renderer });
    return marked.parse(markdownText);
  };
}

export default useMarkdown;
