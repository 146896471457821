import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Box } from '@mui/material';
import React from 'react';
import packageJson from '../../../package.json';
import { useER } from '../../context/ERProvider';
import useMarkdown from '../../hooks/useMarkdown';
import PageWrapper from '../../reusable/PageWrapper/PageWrapper';

function Imprint() {
  const { t } = useTranslation('v2_main');
  const { isLoading } = useER();
  const marked = useMarkdown();

  const parsedMDImprintText = marked(t('impressum'))
    .replace('{copyright_year}', DateTime.now().year)
    .replace('{version}', packageJson.version);

  return (
    <PageWrapper
      metaTitle={t('title-energy-reporter-imprint')}
      metaDescription={t('description-energy-reporter-imprint')}
      siteTitle={t('imprint-title')}
    >
      {!isLoading && (
        <Box
          sx={{ marginTop: '24px' }}
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{
            __html: parsedMDImprintText,
          }}
        />
      )}
    </PageWrapper>
  );
}

export default Imprint;
