import { KPIIcons } from './icons/Icons';
import { RGBAToHexA } from './color-tools';

export const colorsRGBA = {
  Text: {
    rgba: 'rgba(40,40,40, 1)',
  },
  Grau: {
    rgba: 'rgba(170, 170, 170, 1)',
  },
  GrauLight: {
    rgba: 'rgb(238,238,238)',
  },
  Violett: {
    rgba: 'rgb(83,14,139)',
  },
  Gruen: {
    rgba: 'rgba(14, 139, 49, 1)',
  },
  Blau: {
    rgba: 'rgba(14, 114, 181, 1)',
  },
  Gruen30Schwarz: {
    rgba: 'rgba(10, 97, 34, 1)',
  },
  Blau30Schwarz: {
    rgba: 'rgba(10, 80, 126, 1)',
  },
  Gruen40Weiss: {
    rgba: 'rgba(218,233,219, 1)',
  },
  GruenMap: {
    rgba: 'rgba(169,217,190, 1)',
  },
  GruenPlaceholder: {
    rgba: 'rgba(231,242,233, 1)',
  },
  Blau40Weiss: {
    rgba: 'rgba(110, 170, 211, 1)',
  },
  Gruen50Schwarz: {
    rgba: 'rgba(7, 69, 24, 1)',
  },
  Blau50Scwarz: {
    rgba: 'rgba(7, 57, 90, 1)',
  },
  BlauGeoimpact: {
    rgba: 'rgba(14,114,181, 1)',
  },
  White: {
    rgba: 'rgb(255,255,255)',
  },
};
export const colors = {};
Object.keys(colorsRGBA).forEach((key) => {
  colors[key] = {
    ...colorsRGBA[key],
    hex: RGBAToHexA(colorsRGBA[key].rgba, false),
  };
});

const kpiCommonConfig = {
  unit: 'percentage',
  unitShort: 'percentage',
  multiplier: 1,
};

export const KPI_CONFIG = {
  emobilityshare: {
    ...kpiCommonConfig,
    color: colors.Blau30Schwarz,
    iconPath: KPIIcons['icon_elektromobilitaet_personenfahrzeuge_white.svg'],
    // iconPathFilled: KPIIcons['icon_elektromobilitaet_personenfahrzeuge_filled.svg'],
    multiplier: 100,
  },
  pvusage: {
    ...kpiCommonConfig,
    color: colors.Gruen,
    iconPath: KPIIcons['icon_solarenergie_white.svg'],
    // iconPathFilled: KPIIcons['icon_solarenergie_filled.svg'],
    multiplier: 100,
  },
  renewableheatingshare: {
    ...kpiCommonConfig,
    color: colors.BlauGeoimpact,
    iconPath: KPIIcons['icon_erneuerbare_heizung_white.svg'],
    // iconPathFilled: KPIIcons['icon_erneuerbare_heizung_filled.svg'],
    multiplier: 100,
  },
  elecconsumption: {
    ...kpiCommonConfig,
    color: colors.Gruen30Schwarz,
    iconPath: KPIIcons['icon_stromverbrauch_person_white.svg'],
    // iconPathFilled: KPIIcons['icon_stromverbrauch_person_filled.svg'],
    unit: 'mwhypp',
    unitShort: 'mwhypp',
  },
  renelecproduction: {
    ...kpiCommonConfig,
    color: colors.Violett,
    iconPath: KPIIcons['icon_stromproduktion_erneuerbare_energien_white.svg'],
    // iconPathFilled: KPIIcons['icon_stromproduktion_erneuerbare_energien_filled.svg'],
    unit: 'mwhypp',
    unitShort: 'mwhypp',
  },
};

export const CHIP_COLORS = {
  COUNTRY: colors.Grau,
  CANTON: colors.Blau,
  MUNICIPALITY: colors.Blau40Weiss,
};

export const supportedLanguages = [
  'de-CH',
  'fr-CH',
  'it-CH',
];
