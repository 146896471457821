const updateQueryParam = (url, key, value) => {
  // Parse the URL
  const urlObj = new URL(url);
  // Update the query parameter
  urlObj.searchParams.set(key, value);
  // Assemble the modified URL
  return urlObj.toString();
};

export {
  // eslint-disable-next-line import/prefer-default-export
  updateQueryParam,
};
