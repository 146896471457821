import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useER } from '../../context/ERProvider';
import PageWrapper from '../../reusable/PageWrapper/PageWrapper';
import useMarkdown from '../../hooks/useMarkdown';
import { extractHeadings, scrollToHeading } from '../../utils/utils';
import TableOfContents from '../../reusable/TableOfContents/TableOfContents';

function Faq() {
  const { isLoading } = useER();
  const marked = useMarkdown();
  const { t, i18n } = useTranslation('v2_faq');
  const faqLngFallbackKeys = i18n.getDataByLanguage('de-CH').v2_faq;
  const faqTranslations = i18n.getDataByLanguage(i18n.language).v2_faq;

  const [toc, setToc] = useState([]); // Table of Content

  const mergedTranslations = useMemo(() => (
    { ...faqLngFallbackKeys, ...faqTranslations }
  ), [faqLngFallbackKeys, faqTranslations]);

  const entireHTML = useMemo(() => {
    let faqHtml = '<div class="faq-container">';

    const questionKeys = Object.keys(mergedTranslations)
      .filter((key) => key.startsWith('faq-question-'))
      .sort((a, b) => {
        // Extract the numeric part from both strings
        const numA = parseInt(a.split('-')[2], 10);
        const numB = parseInt(b.split('-')[2], 10);
        return numA - numB;
      });

    questionKeys.forEach((questionKey) => {
      const questionNumber = questionKey.split('-')[2];
      const answerKey = `faq-answer-${questionNumber}`;

      if (mergedTranslations[answerKey]) {
        const questionHtml = marked(mergedTranslations[questionKey]);
        const answerHtml = marked(mergedTranslations[answerKey]);

        faqHtml += `
          <div class="faq-item">
            <div class="question">${questionHtml}</div>
            <div class="answer">${answerHtml}</div>
          </div>
        `;
      }
    });

    faqHtml += '</div>';
    return faqHtml;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergedTranslations]);

  useEffect(() => {
    // log.debug('entireHTML: ', entireHTML);
    const tocHeadings = extractHeadings(entireHTML);
    setToc(tocHeadings);
  }, [entireHTML]);

  const handleHeadingClick = (id) => scrollToHeading(id);

  return (
    <PageWrapper
      metaTitle={t('title-energy-reporter-faq')}
      metaDescription={t('description-energy-reporter-faq')}
      siteTitle={t('faq-title')}
    >
      {!isLoading && <TableOfContents headings={toc} onHeadingClick={handleHeadingClick} />}
      {!isLoading && (
        <Box
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{
            __html: entireHTML,
          }}
        />
      )}
    </PageWrapper>
  );
}

export default Faq;
