// Utility function to calculate KPI metadata
import { KPI_CONFIG } from '../utils/constants';

// eslint-disable-next-line import/prefer-default-export
export const calculateKpiMetaData = (kpiData) => {
  const newMetaData = {};
  Object.keys(KPI_CONFIG).forEach((kpiName) => {
    let min = null;
    let max = null;
    let total = 0;
    let minId = null;
    let maxId = null;
    let count = 0;
    let threshold = null;

    Object.entries(kpiData).forEach(([id, data]) => {
      const { value } = data[kpiName].values;
      if (
        typeof value === 'number'
      ) {
        total += value;
        count += 1;
        if (value < min || min === null) {
          min = value;
          minId = id;
        }

        if (value > max || min === null) {
          max = value;
          maxId = id;
        }
      }
      threshold = data[kpiName].values?.valueThreshold || null;
    });

    newMetaData[kpiName] = {
      min: { value: min, id: minId },
      max: { value: max, id: maxId },
      avg: count > 0 ? total / count : 0,
      threshold,
    };
  });
  return newMetaData;
};
