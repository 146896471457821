import {
  List, ListItemButton, ListItemText, Typography,
} from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../utils/constants';

function TableOfContents({ headings, onHeadingClick }) {
  const { t } = useTranslation(['v2_main']);

  const getHeadingStyle = (tagName) => {
    switch (tagName) {
      case 'H2':
        return { fontSize: '0.98rem' };
      case 'H3':
        return { fontSize: '0.92rem' };
      case 'H4':
        return { fontSize: '0.88rem' };
      default:
        return {};
    }
  };

  const renderHeadings = (headingsList) => (
    headingsList.map((heading) => (
      <Fragment key={JSON.stringify([heading.level, heading.text])}>
        <ListItemButton
          disableGutters
          onClick={() => onHeadingClick(heading.id)}
          sx={{
            padding: 0,
            pl: `${(parseInt(heading.tag.substring(1), 10) - 1.92)}rem`,
          }}
        >
          <ListItemText
            primary={(
              <Typography
                sx={getHeadingStyle(heading.tag)}
                color={colors.BlauGeoimpact.rgba}
              >
                {heading.text}
              </Typography>
            )}
          />
        </ListItemButton>
        {heading.children && heading.children.length > 0 && (
          <List disablePadding>
            {renderHeadings(heading.children)}
          </List>
        )}
      </Fragment>
    ))
  );

  return (
    <>
      <Typography
        variant="b"
        component="h2"
        sx={{
          marginTop: '24px',
          marginBottom: '16px',
        }}
      >
        {t('table-of-content')}
      </Typography>
      <List disablePadding>
        {renderHeadings(headings)}
      </List>
    </>
  );
}

export default TableOfContents;
