import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { supportedLanguages } from '../../utils/constants';

export default function LangSwitch() {
  const { i18n } = useTranslation('v2_main');
  return (
    <div>
      {supportedLanguages.sort()
        .map((lang) => (
          <Button
            key={lang}
            disabled={lang === i18n.language}
            onClick={() => { i18n.changeLanguage(lang); }}
          >
            {lang.split('-')[0]}
          </Button>
        ))}
    </div>
  );
}
