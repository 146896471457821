import { Suspense, lazy } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

// IFRAME RESIZER
// The second file (iframeResizer.contentWindow.min.js) needs placing in the
// page(s) contained within your iFrame. This file is designed to be a guest
// on someone else's system, so has no dependencies and won't do anything until
// it's activated by a message from the containing page.
// https://github.com/davidjbradshaw/iframe-resizer
// eslint-disable-next-line no-unused-vars
import * as iframeResizer from 'iframe-resizer/js/iframeResizer.contentWindow';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ERProvider } from './context/ERProvider';
import { DatabaseProvider } from './context/DatabaseProvider';
import { useParamsLang } from './hooks/useParamsLang';
import { UIStateProvider } from './context/UIStateProvider';
import Methodology from './components/Methodology/Methodology';
import CustomLinearProgress from './reusable/Loader/CustomLinearProgress';
import Imprint from './components/Imprint/Imprint';
import Faq from './components/FAQ/Faq';

export const theme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const Layout = lazy(() => import('./components/Layout/Layout'));

function Router() {
  useParamsLang();

  return (
    <Box className="App" m="12px" mb="36px">
      <Route exact path="/">
        <Layout />
      </Route>
      <Route exact path="/methodology">
        <Methodology />
      </Route>
      <Route exact path="/imprint-and-privacy-policy">
        <Imprint />
      </Route>
      <Route exact path="/faq">
        <Faq />
      </Route>
    </Box>
  );
}

function App() {
  const { t } = useTranslation(['v2_main'], { useSuspense: false });

  return (
    <DatabaseProvider>
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ERProvider>
              <UIStateProvider>
                <Helmet>
                  <title>
                    {t('title-energy-reporter')}
                  </title>
                  <meta
                    name="description"
                    content={t('we-monitor-the-development')}
                  />
                </Helmet>
                <Suspense fallback={(<CustomLinearProgress />)}>
                  <BrowserRouter>
                    <Router />
                  </BrowserRouter>
                </Suspense>
              </UIStateProvider>
            </ERProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </HelmetProvider>
    </DatabaseProvider>
  );
}

export default App;
