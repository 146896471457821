import { LinearProgress } from '@mui/material';
import React from 'react';
import { colors } from '../../utils/constants';

const linearProgressStyle = {
  display: 'block',
  width: '100%',
  backgroundColor: `${colors.Gruen.hex}`,
};

function CustomLinearProgress() {
  return <LinearProgress style={linearProgressStyle} />;
}

export default CustomLinearProgress;
