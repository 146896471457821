import log from 'loglevel';
import env from '../env/env';

const logger = (initiator = '') => {
  if (env.buildEnv === 'production') {
    log.setLevel('warn'); // In production, only show warnings and errors
  } else {
    log.setLevel('debug'); // In development, show all logs
  }

  const logWithInitiator = (level, message, ...args) => {
    log.setLevel(log.getLevel()); // Reinitialize loglevel to make sure it's set correctly
    if (env.buildEnv === 'production' && level === 'debug') {
      return; // Skip debug logs in production
    }

    const prefix = initiator ? `[${initiator}] ` : '';
    const fullMessage = prefix + message;
    switch (level) {
      case 'trace':
        log.trace(fullMessage, ...args);
        break;
      case 'debug':
        log.debug(fullMessage, ...args);
        break;
      case 'info':
        log.info(fullMessage, ...args);
        break;
      case 'warn':
        log.warn(fullMessage, ...args);
        break;
      case 'error':
        log.error(fullMessage, ...args);
        break;
      default:
        log.debug(fullMessage, ...args);
    }
  };

  return {
    trace: (message, ...args) => logWithInitiator('trace', message, ...args),
    debug: (message, ...args) => logWithInitiator('debug', message, ...args),
    info: (message, ...args) => logWithInitiator('info', message, ...args),
    warn: (message, ...args) => logWithInitiator('warn', message, ...args),
    error: (message, ...args) => logWithInitiator('error', message, ...args),
  };
};

export default logger;
