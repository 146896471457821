import { Box, debounce } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { KPI_CONFIG } from '../../utils/constants';
import { useER } from '../../context/ERProvider';
import CommunityTypes from './CommunityTypes';
import IntroText from './IntroText';
import useMarkdown from '../../hooks/useMarkdown';
import PageWrapper from '../../reusable/PageWrapper/PageWrapper';
import TableOfContents from '../../reusable/TableOfContents/TableOfContents';
import { extractHeadings, scrollToHeading } from '../../utils/utils';
import packageJSON from '../../../package.json';

const getHiddenAnchor = (id) => `<span id="anchor-methodology-${id}" style="visibility: hidden" />`;

/* function Contacts() {
  const { t } = useTranslation(['v2_main']);
  const marked = useMarkdown();

  return (
    <Box
          /!* eslint-disable-next-line react/no-danger *!/
      dangerouslySetInnerHTML={{
        __html: getHTMLString(),
      }}
    />
  );
} */

function Methodology() {
  const marked = useMarkdown();
  const { hash } = useLocation();
  const { isLoading, countryKpiMap } = useER();
  const { t, i18n } = useTranslation(['v2_main']);
  // IntroText + Methodology + CommunityTypes
  const [entireHTML, setEntireHTML] = useState('');
  const [toc, setToc] = useState([]); // Table of Content

  const getDate = useCallback((timestamp) => {
    if (!timestamp) return 'N/A';

    return DateTime.fromSeconds(timestamp)
      .setLocale(i18n.language)
      .toLocaleString(DateTime.DATE_FULL);
  }, [i18n.language]);

  const getKpiValueInPercent = useCallback((kpiName) => {
    const { value } = countryKpiMap[kpiName].values;

    return Math.round(value * 100).toFixed(1);
  }, [countryKpiMap]);

  const getKpiFromDate = useCallback((kpi) => {
    const { dateFrom } = countryKpiMap[kpi].values;

    return DateTime.fromSeconds(dateFrom)
      .setLocale(i18n.language)
      .toLocaleString(DateTime.DATE_FULL);
  }, [countryKpiMap, i18n.language]);

  const getKpiToDate = useCallback((kpi) => {
    const { dateUntil } = countryKpiMap[kpi].values;

    return DateTime.fromSeconds(dateUntil)
      .setLocale(i18n.language)
      .toLocaleString(DateTime.DATE_FULL);
  }, [countryKpiMap, i18n.language]);

  const getConsumption = useCallback((kpiName) => {
    const { value } = countryKpiMap[kpiName].values;
    const roundedValue = parseFloat(value.toFixed(1));
    return roundedValue.toLocaleString(i18n.language);
  }, [countryKpiMap, i18n.language]);

  const getConsumptionAbsolute = useCallback((kpiName) => {
    const { valueAbsolute } = countryKpiMap[kpiName].values;
    const roundedValue = parseFloat(valueAbsolute.toFixed(1));
    return roundedValue.toLocaleString(i18n.language);
  }, [countryKpiMap, i18n.language]);

  const getTimespan = useCallback(
    (kpi) => `${getKpiFromDate(kpi)} - ${getKpiToDate(kpi)}`,
    [getKpiFromDate, getKpiToDate],
  );

  const parsedMDIntroText = marked(t('methodology-site-introduction-text'));

  const renderMethodologyString = useMemo(() => {
    if (isLoading || !Object.keys(countryKpiMap).length) return '';

    const kpiString = Object.keys(KPI_CONFIG).sort((a, b) => {
      const indexA = Object.keys(KPI_CONFIG).indexOf(a);
      const indexB = Object.keys(KPI_CONFIG).indexOf(b);
      return indexA - indexB;
    }).reduce((result, kpi) => {
      const translationKey = t(`kpi-description-${kpi}-markdown`);
      const kpiInfo = countryKpiMap[kpi];
      const anchorId = `{anchor-methodology-${kpi}}`;
      const anchorElement = getHiddenAnchor(kpi);

      let parsedMD = marked(translationKey)
        .replaceAll(anchorId, anchorElement)
        .replaceAll('{last-update}', getDate(kpiInfo?.lastupdatedData));

      if (['pvusage', 'emobilityshare', 'renewableheatingshare'].includes(kpi)) {
        parsedMD = parsedMD.replaceAll('{swiss-percentage}', `${getKpiValueInPercent(kpi)}%`);
      }

      if (kpi === 'emobilityshare') {
        parsedMD = parsedMD
          .replaceAll(
            '{last-update-emobilitychargingspots}',
            getDate(countryKpiMap.emobilitychargingspots?.lastupdatedData),
          );
      }

      if (kpi === 'elecconsumption') {
        parsedMD = parsedMD
          .replaceAll('{elecc-date-from}', getKpiFromDate(kpi))
          .replaceAll('{elecc-date-to}', getKpiToDate(kpi))
          .replaceAll('{elecc-pro-person}', getConsumption(kpi))
          .replaceAll('{elecc-total-swiss}', getConsumptionAbsolute(kpi))
          .replaceAll('{elecc-timespan}', getTimespan(kpi));
      }
      if (kpi === 'renelecproduction') {
        parsedMD = parsedMD
          .replaceAll('{renelec-date-from}', getKpiFromDate(kpi))
          .replaceAll('{renelec-date-to}', getKpiToDate(kpi))
          .replaceAll('{renelec-pro-person}', getConsumption(kpi))
          .replaceAll('{renelec-total-swiss}', getConsumptionAbsolute(kpi))
          .replaceAll('{renelec-timespan}', getTimespan(kpi));
      }

      return result + parsedMD;
    }, '');
    return kpiString;
  }, [
    isLoading,
    countryKpiMap,
    t, marked,
    getDate,
    getKpiValueInPercent,
    getKpiFromDate,
    getKpiToDate,
    getConsumption,
    getConsumptionAbsolute,
    getTimespan,
  ]);

  const contactsString = useMemo(() => {
    const contactTranslation = t('methodology-contacts');
    const anchorPlaceholder = '{anchor-methodology-contacts}';
    const anchorElement = getHiddenAnchor('contacts');
    return marked(contactTranslation)
      .replaceAll(anchorPlaceholder, anchorElement)
      .replaceAll('{curr-year}', DateTime.now().get('year'))
      .replaceAll('{version}', packageJSON.version);
  }, [marked, t]);

  const parsedMDCommunityTypes = marked(t('community-types-intro-text'))
    .replaceAll('{anchor-community-types}', '<span id="anchor-community-types" style="visibility: hidden" />');

  useEffect(() => {
    const wholeContent = parsedMDIntroText
        + renderMethodologyString
        + parsedMDCommunityTypes
        + contactsString;
    setEntireHTML(wholeContent);
  }, [renderMethodologyString, parsedMDCommunityTypes, parsedMDIntroText, contactsString]);

  useEffect(() => {
    const tocHeadings = extractHeadings(entireHTML);
    setToc(tocHeadings);
  }, [entireHTML]);

  useEffect(() => {
    // Re-trigger anchor navigation
    const debounceHash = debounce(() => {
      const originalHash = hash;
      location.hash = ''; // eslint-disable-line no-restricted-globals
      location.hash = originalHash; // eslint-disable-line no-restricted-globals
    }, 40);

    if (!isLoading && Object.keys(countryKpiMap).length && hash) {
      debounceHash();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, countryKpiMap]);

  const handleHeadingClick = (id) => scrollToHeading(id);

  return (
    <PageWrapper
      metaTitle={t('title-energy-reporter-methodology')}
      metaDescription={t('description-energy-reporter-methodology')}
      siteTitle={t('methodology-title')}
    >
      {!isLoading && <TableOfContents headings={toc} onHeadingClick={handleHeadingClick} />}
      {!isLoading && <IntroText parsedMD={parsedMDIntroText} />}
      {!isLoading && (
      <Box
              /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{
          __html: renderMethodologyString,
        }}
      />
      )}
      {!isLoading && <CommunityTypes parsedMD={parsedMDCommunityTypes} /> }
      {!isLoading && (
      <Box
          /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{
          __html: contactsString,
        }}
      />
      )}
    </PageWrapper>
  );
}

export default Methodology;
