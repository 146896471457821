import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import i18next from 'i18next';
import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import qs from 'qs';
import * as serviceWorker from './serviceWorker';
import App from './App';
import env from './env/env';
import logger from './utils/logger';
import { supportedLanguages } from './utils/constants';

import '@fontsource/open-sans';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';
import * as urlUtils from './utils/urlUtils.mjs';

const getQueryParameterLanguage = () => {
  const paramsEncoded = window.location.search.substring(1);
  const qString = qs.parse(paramsEncoded);
  if (qString?.params) {
    const params = JSON.parse(qString.params);
    return params.lang;
  }
  return 'de-CH';
};

const log = logger('index');
log.info(`Starting EnergieReporter app in ${process.env.NODE_ENV} mode.`, {
  processEnv: process.env,
  env,
});

i18next
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: getQueryParameterLanguage(),
    fallbackLng: 'de-CH',
    backend: {
      projectId: env.locize.projectId,
      apiKey: env.locize.apiKey,
      version: env.locize.version,
      referenceLng: 'de-CH',
    },
    supportedLngs: supportedLanguages,
    preload: supportedLanguages,
  });
i18next.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
  // Every time the language changes, replace the language query parameter
  // of the actual url to reflect the newly used language.
  try {
    const paramsEncoded = window.location.search.substring(1);
    const qString = qs.parse(paramsEncoded);

    let paramsOriginal = {};
    if (qString?.params) {
      paramsOriginal = JSON.parse(qString.params);
    }

    // Parse the URL to get the existing parameters
    const updatedUrl = urlUtils.updateQueryParam(window.location.href, 'params', JSON.stringify({
      ...paramsOriginal,
      lang: lng,
    }));
    window.history.pushState({}, '', `${updatedUrl}`);
  } catch (error) {
    // Handle errors that might occur during parsing or filtering
    log.error('Error parsing the language from the URL parameters:', error);
  }
});

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
