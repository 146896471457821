import {
  createContext, useCallback, useContext, useEffect, useState,
} from 'react';
import logger from '../utils/logger';

const log = logger('UIStateProvider');

const UIStateContext = createContext(null);

export function UIStateProvider({ children }) {
  // App Interaction Logic (search, select municipality or canton)
  const [detailSelection, setDetailSelection] = useState(null);
  const [openTooltipId, setOpenTooltipId] = useState(null);

  useEffect(() => {
    log.debug("UIStateProvider's state changed", detailSelection);
  }, [detailSelection]);

  const api = {
    detailSelection,
    setDetailSelection: useCallback((state) => {
      setDetailSelection(state);
    }, []),
    openTooltipId,
    setOpenTooltipId: useCallback((state) => {
      setOpenTooltipId(state);
    }, []),
  };

  return (
    <UIStateContext.Provider value={api}>
      {children}
    </UIStateContext.Provider>
  );
}

export function useUIState() {
  const context = useContext(UIStateContext);
  if (context === null) {
    throw new Error('useUIState must be used inside a UIStateContext.Provider');
  }
  return context;
}
