import { Box } from '@mui/material';

function IntroText({ parsedMD }) {
  return (
    <Box
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={{
        __html: parsedMD,
      }}
    />
  );
}

export default IntroText;
