import {
  createContext, useCallback, useContext, useState,
} from 'react';
import PouchDB from 'pouchdb';
import env from '../env/env';
import logger from '../utils/logger';
import { getAllDocs } from './db-utils';

const log = logger('DatabaseProvider');

const DatabaseContext = createContext(null);

export function DatabaseProvider({ children }) {
  const [db] = useState(new PouchDB(`${env.cloudant.cloudantURL}/${env.cloudant.dbName}`));
  const getAllDocsByKey = useCallback(async (key) => {
    try {
      return await getAllDocs(db, key);
    } catch (error) {
      log.error(`Error fetching ${key} docs:`, error);
      throw error;
    }
  }, [db]);

  // TODO: implement method which prepares the data for download

  // The context value that will be provided to the rest of the app
  const api = {
    getAllDocsByKey,
  };

  return (
    <DatabaseContext.Provider value={api}>
      {children}
    </DatabaseContext.Provider>
  );
}

export const useDatabase = () => useContext(DatabaseContext);
