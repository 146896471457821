import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Breadcrumbs, Container, Link, Typography,
} from '@mui/material';
import CustomLinearProgress from '../Loader/CustomLinearProgress';
import { colors } from '../../utils/constants';
import { useER } from '../../context/ERProvider';
import Header from '../Header/Header';

const PageWrapper = ({
  metaTitle, metaDescription, siteTitle, children,
}) => {
  const { isLoading } = useER();
  const { t } = useTranslation('v2_main');

  const green = colors.Gruen.rgba;
  const blue = colors.Blau.rgba;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Container
        disableGutters
        maxWidth="lg"
      >
        {isLoading && <CustomLinearProgress />}
        {!isLoading && <Header hasIntro={false} />}
        <Box sx={{ mt: 2, mb: 2 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" href="/">
              <span style={{ color: green }}>{t('title-part-energie')}</span>
              <span style={{ color: blue }}>{t('title-part-reporter')}</span>
            </Link>
            <Typography color="text.primary">{siteTitle}</Typography>
          </Breadcrumbs>
        </Box>
        {children}
      </Container>
    </>
  );
};

export default PageWrapper;
