import logger from './logger';

const log = logger('utils');

export const extractHeadings = (htmlContent) => {
  const virtualElement = document.createElement('div');
  virtualElement.innerHTML = htmlContent;

  const headings = virtualElement.querySelectorAll('h1, h2, h3, h4, h5, h6');
  const tree = [];
  let currentLevel = 0;
  let currentNode = { children: tree };

  headings.forEach((heading, index) => {
    const level = parseInt(heading.tagName[1], 10);
    const headingText = heading.textContent.toLowerCase().trim().replace(/\s+/g, '-');
    const id = `${headingText}-${index + 1}`;
    const node = {
      id, level, tag: heading.tagName, text: heading.textContent, children: [],
    };

    // Adjust the tree for skipped heading levels
    while (currentNode && level <= currentLevel) {
      currentNode = currentNode.parent;
      currentLevel -= 1;
    }

    // Handle case where a heading level is skipped
    if (!currentNode) {
      currentNode = { children: tree };
      while (currentLevel < level - 1) {
        const fillerNode = { level: currentLevel + 1, children: [], parent: currentNode };
        currentNode.children.push(fillerNode);
        currentNode = fillerNode;
        currentLevel += 1;
      }
    }

    if (!currentNode.children) {
      currentNode.children = [];
    }

    currentNode.children.push(node);
    node.parent = currentNode;
    currentNode = node;
    currentLevel = level;
  });

  return tree;
};

export const scrollToHeading = (id) => {
  const elementPosition = parseInt(id.split('-')[id.split('-').length - 1], 10);
  const selector = 'h1[id^="heading-"], h2[id^="heading-"], h3[id^="heading-"], h4[id^="heading-"], h5[id^="heading-"], h6[id^="heading-"]';
  const allMatchingHeadings = Array.from(document.querySelectorAll(selector));
  const targetHeading = allMatchingHeadings[elementPosition - 1];

  if (targetHeading) {
    targetHeading.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    window.history.pushState({}, '', `#${targetHeading.id}`);
  } else {
    log.debug('Section not found: ', id);
  }
};
