import log from 'loglevel';

function importAll(r) {
  const images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  log.debug('importAll', {
    r, images,
  });
  return images;
}
const KPIIcons = importAll(require.context('./images/kpi-icons'), false, /\.(png|jpe?g|svg)$/);
const TechIcons = importAll(require.context('./images/tech-icons'), false, /\.(png|jpe?g|svg)$/);
const PartnerIcons = importAll(require.context('./images/partner-icons'), false, /\.(png|jpe?g|svg)$/);

export {
  KPIIcons, TechIcons, PartnerIcons,
};
