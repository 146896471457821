import {
  Box,
  Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useER } from '../../context/ERProvider';

function CommunityTypes({ parsedMD }) {
  const { municipalityTypeKpi } = useER();
  const { t } = useTranslation(['v2_main']);

  return (
    <>
      <Box
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{
          __html: parsedMD,
        }}
      />
      <TableContainer component={Paper} sx={{ marginTop: '12px', maxWidth: 800 }}>
        <Table size="small">
          <TableBody>
            {Object.keys(municipalityTypeKpi).sort().map((cardType) => (
              <TableRow key={cardType}>
                <TableCell component="th" scope="row">
                  <b>{t(`card-type-${cardType}`)}</b>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    style={{
                      display: 'flex', flex: 3, marginLeft: '10px',
                    }}
                  >
                    {t(`card-type-${cardType}-description`)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default CommunityTypes;
