// eslint-disable-next-line import/prefer-default-export
export async function getAllDocs(db, key) {
  return db.allDocs({
    // The 'startkey' specifies the beginning of the range of documents to be fetched.
    // Here, it is set to 'municipalitykpi:' to start fetching documents
    // whose ID begins with this string.
    startkey: `${key}:`,
    // The 'endkey' specifies the end of the range of documents to be fetched.
    // '\ufff0' is a high Unicode character which acts as a kind of wildcard.
    // By setting the endkey to 'municipalitykpi:\ufff0', we are effectively
    // asking for all documents whose ID starts with 'municipalitykpi:' and continues
    // up to this high Unicode character. This ensures that all documents with IDs that
    // start with 'municipalitykpi:' are included in the result.
    endkey: `${key}:\ufff0`,
    include_docs: true,
  });
}
