import { useEffect, useState } from 'react';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import logger from '../utils/logger';
import { supportedLanguages } from '../utils/constants';

const log = logger('useParamsLang');
// eslint-disable-next-line import/prefer-default-export
export function useParamsLang() {
  const { i18n } = useTranslation('v2_main');
  const [language, setLanguage] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        // Extract and parse the query string
        const paramsEncoded = window.location.search.substring(1);
        const qString = qs.parse(paramsEncoded);

        if (qString.params) {
          const sharedState = JSON.parse(qString.params);
          const { lang } = sharedState;
          if (supportedLanguages.includes(lang)) {
            await i18n.changeLanguage(lang);
            setLanguage(lang);
          }
        }
      } catch (error) {
        // Handle errors that might occur during parsing or filtering
        log.error('Error parsing the language from the URL parameters:', error);
      }
    })();
  }, [i18n]);
  return {
    language,
  };
}
