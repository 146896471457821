let env = null;

const commonEnv = {
  buildEnv: process.env.REACT_APP_BUILD_ENV,
  sep: {
    opendata: {
      baseUrl: 'https://opendata.geoimpact.ch',
    },
  },
  cloudant: {
    cloudantURL: 'https://services.swissenergyplanning.ch',
    dbName: 'energy_reporter',
  },
};

if (process.env.REACT_APP_BUILD_ENV === 'development') {
  env = {
    ...commonEnv,
    locize: {
      projectId: 'f391367b-f8ea-4f61-a3e4-b19b41297f85',
      apiKey: 'c3810f36-d390-4eae-a689-302c179a7779',
      version: 'development',
    },
  };
} else if (process.env.REACT_APP_BUILD_ENV === 'production') {
  env = {
    ...commonEnv,
    locize: {
      projectId: 'f391367b-f8ea-4f61-a3e4-b19b41297f85',
      apiKey: '3f5f1db7-6e66-4896-b5d6-80e6a2fef9da',
      version: 'latest',
    },
  };
} else {
  throw new Error('The environment variable REACT_APP_BUILD_ENV has to be set to "development" or "production"');
}

export default {
  ...env,
};
