import {
  Box, Link, Typography, Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { colors } from '../../utils/constants';
import geoimpactLogoPNG from '../../utils/icons/images/geoimpact_logo.png';
import LangSwitch from '../LangSwitch/LangSwitch';

function Header({ hasIntro = true }) {
  const [t] = useTranslation(['v2_main']);
  const theme = useTheme();
  const isLessThanMedium = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack spacing={2}>
      <Stack direction={{ xs: 'column-reverse', md: 'row' }} alignItems="center">
        <Typography
          variant="h1"
          sx={{ fontWeight: 800 }}
          fontSize={{
            xs: '1.9rem', sm: '3.6rem', md: '4.2rem', lg: '6rem',
          }}
          textAlign={{ xs: 'center', md: 'start' }}
        >
          <span style={{ color: colors.Gruen.rgba }}>
            {t('title-part-energie')}
          </span>
          <span style={{ color: colors.Blau.rgba }}>
            {t('title-part-reporter')}
          </span>
        </Typography>
        <Stack
          ml={{ xs: 'auto' }}
          mr={{ xs: 'auto', md: 0 }}
          alignItems="center"
        >
          <Link
            href="https://www.geoimpact.ch/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={geoimpactLogoPNG}
              alt="geoimpact logo"
              loading="lazy"
              style={{
                width: isLessThanMedium ? '60px' : '80px',
              }}
            />
          </Link>
          <LangSwitch />
        </Stack>
      </Stack>
      {hasIntro && (
        <Box maxWidth="1000px">
          <Typography variant="p">{t('we-monitor-the-development')}</Typography>
        </Box>
      )}
    </Stack>
  );
}
export default Header;
